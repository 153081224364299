import { createAsyncThunk } from "@reduxjs/toolkit";
import { credKey, url } from "../service/creds"


const query = async ({dateFrom, dateTo}) => {
    let headers = new Headers()

    headers.append('Content-Type','application/x-www-form-urlencoded')
    headers.append('Access-Control-Allow-Origin', 'https://analytic.ringosupply.com');
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS');
    headers.append('Access-Control-Allow-Headers', 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type');

    const data = {
        'key': credKey,
        'data[date_to]': dateTo,
        'data[date_from]': dateFrom
    }  

    const formBody = new URLSearchParams(data).toString();


    let res = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: formBody
    })
    let resData = res.json()
    return resData
}

export const fetchData = createAsyncThunk('get/data', query)

export const productData = createAsyncThunk('get/products', query)

export const salesDataAnalytic = createAsyncThunk('get/between/products', async ({dateBetween1, dateBetween2}) => {    
    let resData1 = await query(dateBetween1)
    let resData2 = await query(dateBetween2)
    return {resData1, resData2}
} )

export const averageCheck = createAsyncThunk('get/average/check', async ({dateBetween1, dateBetween2}) => {    
    let resData1 = await query(dateBetween1)
    let resData2 = await query(dateBetween2)
    return {resData1, resData2}
} )

