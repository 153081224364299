export const getHourlySales = (data, hoursRange) => {

    let allClosedSale = data.filter(el => el.status !== 'Չեղարկված');


    const result = {};
    const filterByDate = allClosedSale.filter(el => {
        const [hourFrom, hourTo ] = hoursRange
        let sale_hours = +(el.date.split(' ')[1].split(':')[0])    
        return sale_hours >= hourFrom && sale_hours <= hourTo  
    }) 

    filterByDate.forEach(order => {
        if (!result[order.branch]) {
            result[order.branch] = {};
        }

        order.products.forEach(product => {
            if (!result[order.branch][product.category]) {
                result[order.branch][product.category] = [];
            }

            let productExists = false;
            for (let i = 0; i < result[order.branch][product.category].length; i++) {
                if (result[order.branch][product.category][i].name === product.name) {
                    result[order.branch][product.category][i].count += parseInt(product.count, 10);
                    productExists = true;
                    break;
                }
            }

            if (!productExists) {
                result[order.branch][product.category].push({
                    name: product.name,
                    count: parseInt(product.count, 10)
                });
            }
        });
    });

    // Сортировка данных по категориям и именам продуктов
    for (const branch in result) {
        for (const category in result[branch]) {
            result[branch][category].sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    return result;
};