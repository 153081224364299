import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";


export const saleFilterSlice = createSlice({
    name: 'saleFilter',
    initialState: {
        dateFrom: dayjs().startOf('month'),
        dateTo: dayjs().endOf('month')
    },
    reducers: {
        setDateFrom(state, action){
            state.dateFrom = action.payload  
        },
        setDateTo(state, action){
            state.dateTo = action.payload
        }
    }
})


export const {
    setDateFrom,
    setDateTo
} = saleFilterSlice.actions
export default saleFilterSlice.reducer
