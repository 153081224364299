import { Box, Button } from "@mui/material"
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useDispatch, useSelector } from "react-redux";
import { setDateFrom, setDateTo } from "../store/saleFilterSlice";
import { fetchData } from "../asyncActions/orders";
import { flexCenter, whiteBorder } from "../service/styles";




function SalesFilter(){

    const {
        dateTo,
        dateFrom,
    } = useSelector(store => store.saleFilter)

    const {dataLoad} = useSelector(store => store.orders)

    const dispatch = useDispatch()

    function handleSubmit(){
        const timeRange = {
            dateFrom: dateFrom.format('YYYY-MM-DD'),
            dateTo: dateTo.format('YYYY-MM-DD')
        }
        dispatch(fetchData(timeRange))
    }


    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={whiteBorder}>
                    <Box sx={flexCenter}>
                        <DatePicker 
                            label='Date from'
                            value={dateFrom}
                            onChange={(newDate) => dispatch(setDateFrom(newDate))}
                        />
                        <DatePicker 
                            label='Date to'
                            value={dateTo}
                            onChange={(newDate) => dispatch(setDateTo(newDate))}
                        />
                    </Box> 
                    <Box sx={flexCenter}>
                        <Button disabled={dataLoad} sx={{justifySelf: 'start'}} variant="outlined" onClick={handleSubmit}>Submit</Button>
                    </Box>
                </Box>
        </LocalizationProvider>
    )
}


export default SalesFilter