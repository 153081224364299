import { Box, CircularProgress, Typography } from "@mui/material"
import SalesAnalyticFilter from "../components/SalesAnalyticFilter"
import { whiteBorder } from "../service/styles"
import AnalyticTable from "../components/AnalyticTable/AnalyticTable"
import { useSelector } from "react-redux"





function SalesAnalyticPage() {

    const  {
        loadData,
        data
      } = useSelector(store => store.salesAnalyticFilter)
    

    return (
        <div>
            <SalesAnalyticFilter/>
            <Box sx={{...whiteBorder, alignItems: 'center'}}>
                {loadData ?
                    <CircularProgress/> :
                    (data.length !== 0) ? <AnalyticTable/> : <Typography>No data</Typography>
                }
                
            </Box>
        </div>
    )
}


export default SalesAnalyticPage