
export const flexCenter = {

    display: 'flex',
    justifyContent: 'space-evenly',
}


export const whiteBorder = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: '15px',
    padding: '25px',
    borderRadius: '15px',
    backgroundColor: 'white'
}