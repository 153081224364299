import { Box, CircularProgress, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { whiteBorder } from "../service/styles";
import DailyChart from "./DailyChart";



function SalesDashboardSection(){

    const {
        dataLoad, 
        allSale
    } = useSelector(store => store.orders)

    return(
            <Box sx={{...whiteBorder, alignItems: 'center'}}>
            {dataLoad ? 
                <CircularProgress/> : 
                (allSale.length !== 0) ? <DailyChart/> : <Typography>No data</Typography>
            }
            </Box>
    )
}



export default SalesDashboardSection