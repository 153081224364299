import {combineReducers, configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import ordersSlice from './ordersSlice'
import saleFilterSlice from './saleFilterSlice'
import productFilter from './productFilter'
import salesAnalyticFilterSlice from './salesAnalyticFilterSlice'
import avgCheckFilterSlice from './avgCheckFilterSlice'

const rootReducer = combineReducers({
    saleFilter: saleFilterSlice,
    productFilter: productFilter,
    salesAnalyticFilter: salesAnalyticFilterSlice,
    avgCheckFilterSlice: avgCheckFilterSlice,
    orders: ordersSlice
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
      })
})



