import Filter from "../components/SalesFilter"
import SalesDashboardSection from "../components/SalesDashboardSection"


function SaleDashboardPage(){
    return(
        <div>
            <Filter/>
            <SalesDashboardSection/>
        </div>
    )
}


export default SaleDashboardPage