import {AppBar, Box, Button, Container, Toolbar, Typography} from '@mui/material';
import { Link } from 'react-router-dom'


function Header(){
    return(
        <AppBar position='static'>
            <Container maxWidth='xl'>
                <Toolbar disableGutters sx={{p: 1}}>
                    <Typography 
                        variant='h3'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                          }}
                    >
                            Analytic tool
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '15px' }}>
                        <Link to={'/'}>
                            <Button variant="contained">Dashboard</Button>
                        </Link>
                        <Link to={'/sales/table'}>
                            <Button variant="contained">Sales table</Button>
                        </Link>
                        <Link to={'/sales/analytic'}>
                            <Button variant="contained">sales analytic</Button>
                        </Link>
                        <Link to={'/sales/average_check'}>
                            <Button variant="contained">average check</Button>
                        </Link>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}




export default Header