import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
// import data from './answer.json'
import React from "react";
import { useSelector } from "react-redux";



function AnalyticTable(){

  const  {
    data
  } = useSelector(store => store.salesAnalyticFilter)

  const branches = Object.keys(data)
                        .filter(branch => branch !== 'null')
                        .sort();

  
  const renderedCategories = new Set();

  const mainCeil = { border: '1px solid rgb(138 131 131)'}
  const mainLeftCell = { borderTop: '1px solid rgb(138 131 131)',borderBottom: '1px solid rgb(138 131 131)', borderLeft: '1px solid rgb(138 131 131)' };
  const mainCentertCell = { borderTop: '1px solid rgb(138 131 131)',borderBottom: '1px solid rgb(138 131 131)'};
  const mainRightCell = {borderTop: '1px solid rgb(138 131 131)', borderBottom: '1px solid rgb(138 131 131)', borderRight: '1px solid rgb(138 131 131)' };


  const cellStyle = { border: '1px solid rgba(224, 224, 224, 1)' };
  const leftCell = { borderTop: '1px solid rgba(224, 224, 224, 1)',borderBottom: '1px solid rgba(224, 224, 224, 1)', borderLeft: '1px solid rgba(224, 224, 224, 1)' };
  const centertCell = { borderTop: '1px solid rgba(224, 224, 224, 1)',borderBottom: '1px solid rgba(224, 224, 224, 1)'};
  const rightCell = {borderTop: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)' };

  const PctTableCeil = styled(TableCell)(({value}) => ({
    color: value > 0 ? 'green' : value < 0 ? 'red' : 'black',
    padding: '8px',
    borderRadius: '4px'
    }))


  const toFixedPct = (value) => {
    return (+value.toFixed(2))+'%'
  }


  return (
    <TableContainer component={Paper} sx={{ maxHeight: '90vh' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead >
          <TableRow >
            <TableCell rowSpan={2} style={{ ...mainCeil, position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#fff' }}>
              Product / Category
            </TableCell>
            {branches.map(branch => (
              <TableCell key={branch} colSpan={3} align="center" style={{ ...mainCeil, position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#fff' }}>
                {branch}
              </TableCell>
            ))}
            <TableCell key={'total'} colSpan={3} align="center" style={{ ...mainCeil, position: 'sticky', top: 0, zIndex: 2, backgroundColor: '#fff' }}>
              <strong>Total</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            {branches.map(branch => (
              <React.Fragment key={`${branch}-fields`}>
                <TableCell style={{ ...mainLeftCell, position: 'sticky', top: 58, zIndex: 2, backgroundColor: '#fff' }}>avg 1</TableCell>
                <TableCell style={{ ...mainCentertCell, position: 'sticky', top: 58, zIndex: 2, backgroundColor: '#fff' }}>avg 2</TableCell>
                <TableCell style={{ ...mainRightCell, position: 'sticky', top: 58, zIndex: 2, backgroundColor: '#fff' }}>pct</TableCell>
              </React.Fragment>
            ))}
            <React.Fragment key={`total-fields`}>
              <TableCell style={{ ...mainLeftCell, position: 'sticky', top: 58, zIndex: 2, backgroundColor: '#fff' }}>avg 1</TableCell>
              <TableCell style={{ ...mainCentertCell, position: 'sticky', top: 58, zIndex: 2, backgroundColor: '#fff' }}>avg 2</TableCell>
              <TableCell style={{ ...mainRightCell, position: 'sticky', top: 58, zIndex: 2, backgroundColor: '#fff' }}>pct</TableCell>
            </React.Fragment>
          </TableRow>
        </TableHead>
        <TableBody>
          {branches.flatMap(branch =>
            Object.keys(data[branch]).flatMap(category => {
              if (!renderedCategories.has(category)) {
                renderedCategories.add(category);
                return (
                  <React.Fragment key={category}>
                    <TableRow>
                      <TableCell colSpan={1} style={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff' }}>
                        {category}
                      </TableCell>
                      <TableCell colSpan={branches.length * 4 }></TableCell>
                    </TableRow>
                    {data[branch][category].map(product => {
                      let totalFirstAvg = branches.reduce(
                        (total, b) => total + (data[b][category]?.find(p => p.name === product.name)?.firstAvgCount || 0),
                        0
                      );
                      let totalSecondAvg = branches.reduce(
                        (total, b) => total + (data[b][category]?.find(p => p.name === product.name)?.secondAvgCount || 0),
                        0
                      );
                      let totalPct = (totalSecondAvg * 100) / totalFirstAvg - 100;
  
                      return (
                        <TableRow key={product.name}>
                          <TableCell style={{ ...mainCeil, position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#fff' }}>{product.name}</TableCell>
                          {branches.map(branch => {
                            let firstAvgValue = data[branch][category].find(p => p.name === product.name)?.firstAvgCount || 0;
                            let secondAvgValue = data[branch][category].find(p => p.name === product.name)?.secondAvgCount || 0;
                            let pctValue = data[branch][category].find(p => p.name === product.name)?.pct || 0;
                            return (
                              <React.Fragment key={`${branch}-${product.name}`}>
                                <TableCell style={leftCell}>{firstAvgValue}</TableCell>
                                <TableCell style={centertCell}>{secondAvgValue}</TableCell>
                                <PctTableCeil value={pctValue} style={rightCell}>
                                  {toFixedPct(pctValue)}
                                </PctTableCeil>
                              </React.Fragment>
                            );
                          })}
                          <TableCell style={leftCell}>{totalFirstAvg.toFixed(2)}</TableCell>
                          <TableCell style={centertCell}>{totalSecondAvg.toFixed(2)}</TableCell>
                          <PctTableCeil value={totalPct} style={rightCell}>
                            {toFixedPct(totalPct)}
                          </PctTableCeil>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              }
              return null;
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AnalyticTable