import { createBrowserRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import SaleDashboardPage from "./pages/SaleDashboardPage";
import ProductDashboardPage from "./pages/ProductDashboardPage";
import SalesAnalyticPage from "./pages/SalesAnalyticPage";
import AverageCheckPage from "./pages/AverageChackPage";

export const routes = createBrowserRouter([
  {
    path: '/', 
    element: <Layout/>,
    children: [
        {path: '/', element: <SaleDashboardPage/> },
        {path: '/sales/table', element: <ProductDashboardPage/> },
        {path: '/sales/analytic', element: <SalesAnalyticPage/> },
        {path: '/sales/average_check', element: <AverageCheckPage/> },
    ]
    },  
])