import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react";
import { useSelector } from "react-redux";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));



function TableData(){

    const {
            data
        } = useSelector(store => store.productFilter)
    
    const branches = Object.keys(data)
                                .filter(branch => branch !== 'null')
                                .sort();
    
    const categoriesRendered = new Set();

    
    return (
        <div>
            <TableContainer component={Paper}sx={{ maxHeight: '90vh'}} >
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Category / Product</StyledTableCell>
                        {branches.map(branch => (
                            <StyledTableCell key={branch}>{branch}</StyledTableCell>
                        ))}
                        <StyledTableCell><strong>Total</strong></StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {branches.flatMap(branch => 
                        Object.keys(data[branch]).map(category => {
                            if (categoriesRendered.has(category)) return null;
                            categoriesRendered.add(category);
                            return (
                                <React.Fragment key={category}>
                                    <TableRow>
                                        <TableCell colSpan={branches.length + 2}><strong>{category}</strong></TableCell>
                                    </TableRow>

                                    {data[branch][category].map(product => (
                                        <TableRow key={product.name}>
                                            <TableCell>{product.name}</TableCell>
                                            {branches.map(b => (
                                                <TableCell key={`${b}-${product.name}`}>
                                                    {data[b][category]?.find(p => p.name === product.name)?.count || 0}
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                {branches.reduce((total, b) => {
                                                    return total + (data[b][category]?.find(p => p.name === product.name)?.count || 0);
                                                }, 0)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    
                                </React.Fragment>
                            );
                        })
                    )}
                </TableBody>
            </Table>
            </TableContainer>
        </div>
    )
}


export default TableData