import { Box, Typography, CircularProgress  } from "@mui/material"
import ProductFilter from "../components/ProductFilter"
import TableData from "../components/TabkeData/TabkeData"
import { whiteBorder } from "../service/styles"
import { useSelector } from "react-redux"



function ProductDashboardPage(){


    const {
        loadData,
        data
    } = useSelector(store => store.productFilter)
    
    return(
        <div>
            <ProductFilter/>
            <Box sx={{...whiteBorder, alignItems: 'center'}}>
                {loadData ? 
                    <CircularProgress/> : 
                    (data.length !== 0) ? <TableData/> : <Typography>No data</Typography>
                }
            </Box>
            
        </div>
    )
}


export default ProductDashboardPage