import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material"
// import data from './avgAnswer.json'
import { useSelector } from "react-redux";






function AverageTable(){

    const {
        data
    } = useSelector(store => store.avgCheckFilterSlice)

    const PctTableCeil = styled(TableCell)(({value}) => ({
        color: value > 0.0 ? 'green' : value < 0.0 ? 'red' : 'black',
        padding: '8px',
        borderRadius: '4px'
    }))

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.info.light,
          color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

      
    let averages = Object.entries(data).sort().reverse();

    const mainRightCell = {borderRight: '1px solid rgb(138 131 131)' };
    return (
        <TableContainer component={Paper}>
             <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={mainRightCell} rowSpan={2} align="center">Delivery type</StyledTableCell>
                        <StyledTableCell style={mainRightCell} colSpan={3} align="center">Average Payed</StyledTableCell>
                        <StyledTableCell colSpan={3} align="center">Average Orders</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Avg 1</StyledTableCell>
                        <StyledTableCell>Avg 2</StyledTableCell>
                        <StyledTableCell style={mainRightCell}>Pct</StyledTableCell>
                        <StyledTableCell>Avg 1</StyledTableCell>
                        <StyledTableCell>Avg 2</StyledTableCell>
                        <StyledTableCell>Pct</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {averages.map( ([deliveryType, value]) => 
                            <TableRow>
                                <TableCell style={mainRightCell}><strong>{deliveryType}</strong></TableCell>
                                <TableCell>{value.avgPayed}</TableCell>
                                <TableCell>{value.avgPayedCompare}</TableCell>
                                <PctTableCeil value={value.pctPayed} style={mainRightCell}>{value.pctPayed}%</PctTableCeil>
                                <TableCell>{value.avgOrders}</TableCell>
                                <TableCell>{value.avgOrdersCompare}</TableCell>
                                <PctTableCeil value={value.pctOrders}>{value.pctOrders}%</PctTableCeil>
                            </TableRow>
                    )}
                </TableBody>
             </Table>
        </TableContainer>
    )
}


export default AverageTable