import dayjs from "dayjs";

export  function calculateAverages(data1, data2, branch = 'all') {
    let allClosedSale1 = data1.filter(el => el.status !== 'Չեղարկված');
    let allClosedSale2 = data2.filter(el => el.status !== 'Չեղարկված');

    if (branch !== 'all'){
        allClosedSale1 = allClosedSale1.filter( order => order.branch === branch)
        allClosedSale2 = allClosedSale2.filter( order => order.branch === branch)
    }

    let branches = new Set()

    for (let order of [...allClosedSale1, ...allClosedSale2]){
        branches.add(order.branch)
    }

    const deliveryTypes = {allTypes: {
        totalToBePayed: 0,
        totalOrders: 0,
        totalToBePayed_2: 0,
        totalOrders_2: 0
    }
    };
    const dailySums1 = {}
    const dailySums2 = {}

    allClosedSale1.forEach(order => {
        const date = dayjs(order.date).format('YYYY-MM-DD');

        if (!deliveryTypes[order.delivery_type]) {
            deliveryTypes[order.delivery_type] = {
                totalToBePayed: 0,
                totalOrders: 0,
                totalToBePayed_2: 0,
                totalOrders_2: 0
            };
        }

        if (!dailySums1[date]) {
            dailySums1[date] = 0;
        }

        deliveryTypes.allTypes.totalToBePayed += parseFloat(order.to_be_payed);
        deliveryTypes.allTypes.totalOrders += 1

        deliveryTypes[order.delivery_type].totalToBePayed += parseFloat(order.to_be_payed);
        deliveryTypes[order.delivery_type].totalOrders += 1;
        dailySums1[date] += 1

    })

    allClosedSale2.forEach(order => {
        const date = dayjs(order.date).format('YYYY-MM-DD');

        if (!deliveryTypes[order.delivery_type]) {
            deliveryTypes[order.delivery_type] = {
                totalToBePayed: 0,
                totalOrders: 0,
                totalToBePayed_2: 0,
                totalOrders_2: 0
            };
        }

        if (!dailySums2[date]) {
            dailySums2[date] = 0;
        }

        deliveryTypes.allTypes.totalToBePayed_2 += parseFloat(order.to_be_payed);
        deliveryTypes.allTypes.totalOrders_2 += 1

        deliveryTypes[order.delivery_type].totalToBePayed_2 += parseFloat(order.to_be_payed);
        deliveryTypes[order.delivery_type].totalOrders_2 += 1;

        dailySums2[date] += 1
    })

    let averages = {}
    const totalDays1 = Object.keys(dailySums1).length;
    const totalDays2 = Object.keys(dailySums2).length;

    for (let type in deliveryTypes){
        averages[type] = {
            avgPayed: deliveryTypes[type].totalToBePayed / deliveryTypes[type].totalOrders,
            avgPayedCompare: deliveryTypes[type].totalToBePayed_2 / deliveryTypes[type].totalOrders_2,
            avgOrders: deliveryTypes[type].totalOrders / totalDays1,
            avgOrdersCompare: deliveryTypes[type].totalOrders_2 / totalDays2,
        }
    }


    for (let type in averages){
        averages[type].pctPayed = ((averages[type].avgPayedCompare / averages[type].avgPayed) * 100 ) - 100
        averages[type].pctOrders = ((averages[type].avgOrdersCompare / averages[type].avgOrders) * 100 ) - 100

        for (let value in averages[type]){
            averages[type][value] = +averages[type][value].toFixed(2)
        }
    }
    return {averages, branches: [...branches]}
}