import { createSlice } from "@reduxjs/toolkit";
import { fetchData } from "../asyncActions/orders";
import { totalPricePerDays } from "../service/functions";




export const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        error: null,
        times: [new Date()],
        allSale: [],
        onSpotSale: [],
        onDeliverySale: [],
        onBookingSale : [],
        avgCheck: 0,
        dataLoad: false
    }, 
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state, action) => {
                state.error = null
                state.dataLoad = true
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.error = null                
                state.dataLoad = false
                const {times, allSale, onSpotSale ,onDeliverySale,onBookingSale, avgCheck} = totalPricePerDays(action.payload.orders)
            
                state.times = times
                state.allSale = allSale
                state.onSpotSale = onSpotSale
                state.onDeliverySale = onDeliverySale
                state.onBookingSale = onBookingSale
                state.avgCheck = avgCheck
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.dataLoad = false
                state.times = []
                state.allSale = []
                state.onSpotSale = []
                state.onDeliverySale = []
                state.onBookingSale = []
                state.error = action.payload.toString()
            })
    }
})



export default ordersSlice.reducer