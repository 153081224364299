import dayjs from "dayjs";


export function calculateSalesAnalytic(productsList, dataSet_1, dataSet_2, hoursRange) {
    let allClosedSale1 = dataSet_1.filter(el => el.status !== 'Չեղարկված');
    let allClosedSale2 = dataSet_2.filter(el => el.status !== 'Չեղարկված');

    const result = {};

    function getDaysDiff(data){
        const startDate = dayjs(data[data.length-1].date)
        const endDate = dayjs(data[0].date)
        return endDate.diff(startDate, 'day') + 1
    }

    const filterByDate_1 = allClosedSale1.filter(el => {
        const [hourFrom, hourTo ] = hoursRange
        let sale_hours = +(el.date.split(' ')[1].split(':')[0])    
        return sale_hours >= hourFrom && sale_hours <= hourTo 
    }) 
    
    const filterByDate_2 = allClosedSale2.filter(el => {
        const [hourFrom, hourTo ] = hoursRange
        let sale_hours = +(el.date.split(' ')[1].split(':')[0])    
        return sale_hours >= hourFrom && sale_hours <= hourTo 
    })  

    // Initialize result structure
    for (const branch of new Set([...filterByDate_1.map(sale => sale.branch), ...filterByDate_2.map(sale => sale.branch)])) {
        result[branch] = {};
        for (const category in productsList) {
            result[branch][category] = productsList[category].map(product => ({
                name: product,
                firstAvgCount: 0,
                secondAvgCount: 0
            }));
        }
    }

    // Calculate sales for the first dataset
    for (const sale of filterByDate_1) {
        const branch = sale.branch;
        for (const product of sale.products) {
            const category = product.category;
            const name = product.name;
            const count = parseInt(product.count, 10);
            const categoryProducts = result[branch][category];
            if (categoryProducts){
                const productEntry = categoryProducts.find(p => p.name === name);
                if (productEntry) {
                    productEntry.firstAvgCount += count;
                }
            }
        }
    }

    // Calculate sales for the second dataset
    for (const sale of filterByDate_2) {
        const branch = sale.branch;
        for (const product of sale.products) {
            const category = product.category;
            const name = product.name;
            const count = parseInt(product.count, 10);
            const categoryProducts = result[branch][category];
            if (categoryProducts){
                const productEntry = categoryProducts.find(p => p.name === name);
                if (productEntry) {
                    productEntry.secondAvgCount += count;
                }
            }
        }
    }

    for (const branch in result){
        for (const category in result[branch]){
            for(const product in result[branch][category]){
                let firstAvgCount = result[branch][category][product].firstAvgCount / getDaysDiff(allClosedSale1)
                let secondAvgCount = result[branch][category][product].secondAvgCount / getDaysDiff(allClosedSale2)
                result[branch][category][product].firstAvgCount = +(firstAvgCount).toFixed(2)
                result[branch][category][product].secondAvgCount = +(secondAvgCount).toFixed(2)
                result[branch][category][product].pct = 
                ((result[branch][category][product].secondAvgCount * 100 / firstAvgCount) - 100) || 0
            }
        }
    }

    return result;
}