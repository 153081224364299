import dayjs from "dayjs";
import { routes } from "./routes";
import { RouterProvider } from "react-router-dom";

function App() {

  // console.log(  dayjs().format('YYYY-MM-DD HH:mm')  );
  let date_1 = dayjs('2024-06-01')
  let date_2 = dayjs('2024-06-02')
  console.log(date_2.diff(date_1, 'day'));
  
  return (
    <div>
      <RouterProvider router={routes}/>
    </div>
  );
}

export default App;
