import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { LineChart } from "@mui/x-charts"
import { useState } from "react"
import { useSelector } from "react-redux"
import PieDataChart from "./PieDataChart"
import { flexCenter } from "../service/styles"


function DailyChart(){

    const {
        times, 
        allSale,
        onSpotSale,
        onDeliverySale, 
        onBookingSale,
        avgCheck
    } = useSelector(store => store.orders)

    let [labels, setLabels] = useState({
        allCheck: true,
        spotCheck: true,
        deliveryCheck: true,
        bookingCheck: true,
    })
    
    const checkHandle = (spot, boolean) => {
        let checkedLabels = {...labels} 
        checkedLabels[spot] = boolean
        setLabels(checkedLabels)
    }


    const series = [
        {label: 'All sale', color: 'orange', area: 'true', curve: "linear", data: allSale},
        {label: 'All spot', color: 'green', area: 'true', curve: "linear", data: onSpotSale},
        {label: 'All delivery', color: 'purple',area: 'true',  curve: "linear", data: onDeliverySale},
        {label: 'All booking', color: 'blue', area: 'true', curve: "linear", data: onBookingSale},
    ]

    const totalSum = allSale.reduce((sum, elem) => sum + elem, 0)

    const pieChartData = [
        {id: 1, label: 'Spot', color: 'green', value: (onSpotSale.reduce((sum, elem) => sum + elem, 0) * 100 / totalSum).toFixed(1)},
        {id: 2, label: 'Delivery', color: 'purple', value: (onDeliverySale.reduce((sum, elem) => sum + elem, 0) * 100 / totalSum).toFixed(1)},
        {id: 3, label: 'Booking', color: 'blue', value: (onBookingSale.reduce((sum, elem) => sum + elem, 0) * 100 / totalSum).toFixed(1)}
    ]

    const filterSeries = series.filter((el) => {
        if (!labels.allCheck && el.label === 'All sale') return false
        if (!labels.spotCheck && el.label === 'All spot') return false
        if (!labels.deliveryCheck && el.label === 'All delivery') return false
        if (!labels.bookingCheck && el.label === 'All booking') return false
        return true
    })



    return(
        <Box>
            <FormControlLabel checked={labels.allCheck}
                control={
                    <Checkbox onChange={(e) => checkHandle('allCheck',e.target.checked)}/>
                }
                label='All sale'
                labelPlacement="top"
            />
            <FormControlLabel checked={labels.spotCheck}
                control={
                    <Checkbox onChange={(e) => checkHandle('spotCheck',e.target.checked)}/>
                }
                label='All spot'
                labelPlacement="top"
            />
            <FormControlLabel checked={labels.deliveryCheck}
                control={
                    <Checkbox onChange={(e) => checkHandle('deliveryCheck',e.target.checked)}/>
                }
                label='All delivery'
                labelPlacement="top"
            />
            <FormControlLabel checked={labels.bookingCheck}
                control={
                    <Checkbox onChange={(e) => checkHandle('bookingCheck',e.target.checked)}/>
                }
                label='All booking'
                labelPlacement="top"
            />
            <LineChart
                xAxis={[{
                    scaleType: 'point',    
                    data: times,
                    min: times[0].getTime(),
                    max: times[times.length - 1].getTime()
                }]}
                series={filterSeries}
                width={800}
                height={500}
            />
            <Box sx={{...flexCenter, justifyContent: "center", alignItems: 'center'}}>
                <Typography>Average check: {avgCheck}</Typography>
                <PieDataChart data={pieChartData}/>
            </Box>
        </Box>
    )
}


export default DailyChart